import { useQuery } from "@tanstack/vue-query";
import { userSessionQuery } from "../queries/_userSessionQuery";
import type { User } from "../queries/_userSessionQuery";

export function useUserOrThrow() {
  const { data } = useQuery(userSessionQuery());

  const user = computed(() => data.value?.user ?? undefined);

  if (!isDefined(user))
    throw new Error(
      "User is not authenticated, to use `useUserOrThrow` safely make sure to wrap it in `SignedIn` component",
    );

  return computed(() => user.value);
}

interface UseUserReturn {
  user: ComputedRef<User | undefined>;
  isLoading: Ref<boolean>;
}

export function useUser(): UseUserReturn & PromiseLike<UseUserReturn> {
  const { data, isLoading, suspense } = useQuery(userSessionQuery());

  const user = computed(() => data.value?.user ?? undefined);

  const state: UseUserReturn = {
    user,
    isLoading,
  };

  return {
    ...state,
    async then(onFulfilled, onRejected) {
      return new Promise<UseUserReturn>((resolve) => {
        suspense().then(() => resolve(state));
      }).then(onFulfilled, onRejected);
    },
  };
}

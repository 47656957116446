import { queryOptions } from "@tanstack/vue-query";

export interface User {
  name: string;
  username: string;
  avatarUrl: string;
  isProfileWeak: boolean;
  favoriteOffersCount: number;
  messagesCount: number;
  notificationsCount: number;
  managedCompanies: Array<{
    name: string;
    slug: string;
    logoUrl: string;
    verified: boolean;
    area: { [locale: string]: string };
  }>;
  agreedToGdpr: boolean;
  currency: "CZK" | "EUR" | "USD";
}

export interface VerifiedSession {
  twoFactorVerified: true;
  user: User;
}

export interface UnverifiedSession {
  twoFactorVerified: false;
  user: null;
}

export type Session = VerifiedSession | UnverifiedSession;

export const userSessionQuery = () =>
  queryOptions({
    queryKey: ["session"],
    queryFn: () => {
      return $apiFetch<Session | null>(`/api/front/user`);
    },
    enabled: import.meta.client,
    retry: false,
  });
